angular.module('app.controllers').controller 'ContactCtrl', [
  '$scope', '$rootScope', 'UI', 'APIInquiries', 'APICheckin', '$http'
].append (self, root, UI, APIInquiries, APICheckin, $http) ->

  self.modalType      = 'contact'
  self.exhibitor_id   = null

  self.data_s = null

  self.initWithData = (data = null, type) ->
    self.data_s = data
    self.resetData(type)

  # clear time interval
  self.$on '$destroy', ->
    self.initWithData()

  self.resetData = (modalType = 'contact', close = false, modalMode = 'send') ->
    self.modalType    = modalType
    self.modalMode    = modalMode
    self.formProccess = false
    self.formSuccess  = false
    self.data         = angular.copy(root.session?.user)    
    self.data.gender  = "#{self.data.gender}"

    self.fake = 
      note: ''
      file: null

    self.inquiry = null
    
    unless close
      title = switch self.modalType
        when 'speaker' then 'Kontaktieren Sie uns: ' + self.data_s.name
        when 'message_private' then 'Kontaktieren Sie uns'
        when 'message_company' then 'Kontaktdaten übergeben'
        when 'message' then 'Kontaktdaten übergeben'
        when 'recall' then 'Rückruf anfordern'
        when 'brand' then 'Informationen anfordern: ' + self.data_s.name
        when 'product' then 'Produkt anfragen: ' + self.data_s.title
        else 'Nachricht senden'

      self.inquiry = new APIInquiries
        title: title
        methods: ['notice']
        shortcode: root.user?.shortcode


  contactModalEl = document.getElementById('contactModal')
  root.contactModal = bootstrap.Modal.getOrCreateInstance(contactModalEl) if contactModalEl?

  self.saveForm = (form, name) ->
    self[name] = form

  self.toggleMode = (mode) ->
    self.modalMode = mode

  self.updateData = () ->
    root.$broadcast('updateContact', self.data)

  if contactModalEl?
    
    contactModalEl.addEventListener('show.bs.modal', (event) ->
      self.$applyAsync()
    )

    contactModalEl.addEventListener('hide.bs.modal', (event) ->
      self.resetData(null, true)
      self.$applyAsync()
    )

  self.$on 'openContactData', (event, exhibitor_id, modalType = 'contact', modalMode = 'send') ->
    self.exhibitor_id = exhibitor_id
    self.resetData(modalType, false, modalMode)
    root.contactModal.show()

  self.save = () ->
    return unless root.session?.user?

    self.formProccess = true
    root.session.user = self.data
    (new APICheckin root.session).$save {_private: true, auth_ticket: root.user?.auth_ticket}, (data) ->
      root.session = data

      if self.epForm?
        self.epForm.$setPristine()
        self.epForm.$setUntouched()

      self.toggleMode('send')

      self.formProccess = false
    , (response) ->
      self.formProccess = false
      UI.notify false, response.data.error
    
  self.send = () ->
    self.formProccess  = true
    self.formSuccess   = false

    self.inquiry.exhibitor_id = self.exhibitor_id || self.data_s?.exhibitor_id || null

    description = []
    description.push 'Produkt: ' + self.modalData.title if self.type == 'product'
    description.push '' if self.type == 'product'
    description.push 'Kontaktdaten:'
    description.push if self.data.gender == "true" then 'Herr' else 'Frau'
    description.push (if self.data.title then self.data.title + ' ' else '') + self.data.first_name + ' ' + self.data.last_name + (if self.data.affix then ' ' + self.data.affix else '') 
    description.push self.data.address if self.data.address
    description.push (if self.data.zip then self.data.zip + ' ' else '') + (if self.data.city then self.data.city else '') if self.data.zip || self.data.city
    description.push self.data.phone if self.data.phone 
    description.push ''
    description.push 'E-mail: ' + self.data.email
    description.push '' if self.fake.note
    description.push 'Ihre Nachricht: ' + self.fake.note if self.fake.note

    self.inquiry.description = description.join "\n"

    self.inquiry.$save {_private: true, auth_ticket: root.user?.auth_ticket}, (data) ->
      self.resetData(self.modalType, true)
      self.formSuccess   = true
      
      if self.modalType == 'brand'
        self.toggleFavs('rb', {exhibitor_id: self.data_s.exhibitor_id, entry_id: self.data_s.entry_id, name: self.data_s.name})
      else if self.modalType == 'product'
        self.toggleFavs('rp', {exhibitor_id: self.data_s.exhibitor_id, entry_id: self.data_s.entry_id, title: self.data_s.title})
      else if self.modalType == 'speaker'
        self.customStat(self.exhibitor_id || null, 'send_' + self.modalType, self.data_s.name)
      else
        self.trackEvent({exhibitor_id: self.exhibitor_id || null, description: 'send_' + self.modalType})

      

    , (response) ->
      self.formSuccess   = false
      self.formProccess  = false

      UI.notify false, response.data.error

  self.$watch 'fake.file', (newObj, oldObj) ->
    return unless newObj
    if newObj.size > 5242880
      clearForm()
      UI.notify false, "Send us your application documents as a PDF - max. 5 MB"
 
  clearForm = () ->
    self.fake.file = null

    $timeout () ->
      input = document.getElementById('cff13')
      input.value = ''
      input.dispatchEvent(new Event('change'))
    , 100

  self.getData = () ->
    return unless self.data

    data = []

    name = []
    for i in ['gender', 'first_name', 'last_name']
      field = self.data["#{i}"]
      if i == "gender"
        field = "#{field}" unless typeof(field) == 'string'
        switch field
          when "true"   then field = "Herr"
          when "false"  then field = "Frau"
          when "null"   then field = "keine Anrede"

      if i == "last_name" && self.data.affix
        field += ","

      name.push field

    name = name.join(" ")
    data.push name

   
    address = []
    for i in ['address', 'zip', 'city']
      continue unless self.data["#{i}"]
      address.push self.data["#{i}"]
      if i in ['address']
        address.push "<br>"
    address = address.join(" ")

    data.push(address) if address.length > 0
    data.push(self.data.phone) if self.data.phone
    data.push(self.data.email)

    return data.join("<br>")