angular.module('app.controllers').controller('ContactCtrl', ['$scope', '$rootScope', 'UI', 'APIInquiries', 'APICheckin', '$http'].append(function(self, root, UI, APIInquiries, APICheckin, $http) {
  var clearForm, contactModalEl;
  self.modalType = 'contact';
  self.exhibitor_id = null;
  self.data_s = null;
  self.initWithData = function(data = null, type) {
    self.data_s = data;
    return self.resetData(type);
  };
  // clear time interval
  self.$on('$destroy', function() {
    return self.initWithData();
  });
  self.resetData = function(modalType = 'contact', close = false, modalMode = 'send') {
    var ref, ref1, title;
    self.modalType = modalType;
    self.modalMode = modalMode;
    self.formProccess = false;
    self.formSuccess = false;
    self.data = angular.copy((ref = root.session) != null ? ref.user : void 0);
    self.data.gender = `${self.data.gender}`;
    self.fake = {
      note: '',
      file: null
    };
    self.inquiry = null;
    if (!close) {
      title = (function() {
        switch (self.modalType) {
          case 'speaker':
            return 'Kontaktieren Sie uns: ' + self.data_s.name;
          case 'message_private':
            return 'Kontaktieren Sie uns';
          case 'message_company':
            return 'Kontaktdaten übergeben';
          case 'message':
            return 'Kontaktdaten übergeben';
          case 'recall':
            return 'Rückruf anfordern';
          case 'brand':
            return 'Informationen anfordern: ' + self.data_s.name;
          case 'product':
            return 'Produkt anfragen: ' + self.data_s.title;
          default:
            return 'Nachricht senden';
        }
      })();
      return self.inquiry = new APIInquiries({
        title: title,
        methods: ['notice'],
        shortcode: (ref1 = root.user) != null ? ref1.shortcode : void 0
      });
    }
  };
  contactModalEl = document.getElementById('contactModal');
  if (contactModalEl != null) {
    root.contactModal = bootstrap.Modal.getOrCreateInstance(contactModalEl);
  }
  self.saveForm = function(form, name) {
    return self[name] = form;
  };
  self.toggleMode = function(mode) {
    return self.modalMode = mode;
  };
  self.updateData = function() {
    return root.$broadcast('updateContact', self.data);
  };
  if (contactModalEl != null) {
    contactModalEl.addEventListener('show.bs.modal', function(event) {
      return self.$applyAsync();
    });
    contactModalEl.addEventListener('hide.bs.modal', function(event) {
      self.resetData(null, true);
      return self.$applyAsync();
    });
  }
  self.$on('openContactData', function(event, exhibitor_id, modalType = 'contact', modalMode = 'send') {
    self.exhibitor_id = exhibitor_id;
    self.resetData(modalType, false, modalMode);
    return root.contactModal.show();
  });
  self.save = function() {
    var ref, ref1;
    if (((ref = root.session) != null ? ref.user : void 0) == null) {
      return;
    }
    self.formProccess = true;
    root.session.user = self.data;
    return (new APICheckin(root.session)).$save({
      _private: true,
      auth_ticket: (ref1 = root.user) != null ? ref1.auth_ticket : void 0
    }, function(data) {
      root.session = data;
      if (self.epForm != null) {
        self.epForm.$setPristine();
        self.epForm.$setUntouched();
      }
      self.toggleMode('send');
      return self.formProccess = false;
    }, function(response) {
      self.formProccess = false;
      return UI.notify(false, response.data.error);
    });
  };
  self.send = function() {
    var description, ref, ref1;
    self.formProccess = true;
    self.formSuccess = false;
    self.inquiry.exhibitor_id = self.exhibitor_id || ((ref = self.data_s) != null ? ref.exhibitor_id : void 0) || null;
    description = [];
    if (self.type === 'product') {
      description.push('Produkt: ' + self.modalData.title);
    }
    if (self.type === 'product') {
      description.push('');
    }
    description.push('Kontaktdaten:');
    description.push(self.data.gender === "true" ? 'Herr' : 'Frau');
    description.push((self.data.title ? self.data.title + ' ' : '') + self.data.first_name + ' ' + self.data.last_name + (self.data.affix ? ' ' + self.data.affix : ''));
    if (self.data.address) {
      description.push(self.data.address);
    }
    if (self.data.zip || self.data.city) {
      description.push((self.data.zip ? self.data.zip + ' ' : '') + (self.data.city ? self.data.city : ''));
    }
    if (self.data.phone) {
      description.push(self.data.phone);
    }
    description.push('');
    description.push('E-mail: ' + self.data.email);
    if (self.fake.note) {
      description.push('');
    }
    if (self.fake.note) {
      description.push('Ihre Nachricht: ' + self.fake.note);
    }
    self.inquiry.description = description.join("\n");
    return self.inquiry.$save({
      _private: true,
      auth_ticket: (ref1 = root.user) != null ? ref1.auth_ticket : void 0
    }, function(data) {
      self.resetData(self.modalType, true);
      self.formSuccess = true;
      if (self.modalType === 'brand') {
        return self.toggleFavs('rb', {
          exhibitor_id: self.data_s.exhibitor_id,
          entry_id: self.data_s.entry_id,
          name: self.data_s.name
        });
      } else if (self.modalType === 'product') {
        return self.toggleFavs('rp', {
          exhibitor_id: self.data_s.exhibitor_id,
          entry_id: self.data_s.entry_id,
          title: self.data_s.title
        });
      } else if (self.modalType === 'speaker') {
        return self.customStat(self.exhibitor_id || null, 'send_' + self.modalType, self.data_s.name);
      } else {
        return self.trackEvent({
          exhibitor_id: self.exhibitor_id || null,
          description: 'send_' + self.modalType
        });
      }
    }, function(response) {
      self.formSuccess = false;
      self.formProccess = false;
      return UI.notify(false, response.data.error);
    });
  };
  self.$watch('fake.file', function(newObj, oldObj) {
    if (!newObj) {
      return;
    }
    if (newObj.size > 5242880) {
      clearForm();
      return UI.notify(false, "Send us your application documents as a PDF - max. 5 MB");
    }
  });
  clearForm = function() {
    self.fake.file = null;
    return $timeout(function() {
      var input;
      input = document.getElementById('cff13');
      input.value = '';
      return input.dispatchEvent(new Event('change'));
    }, 100);
  };
  return self.getData = function() {
    var address, data, field, i, j, k, len, len1, name, ref, ref1;
    if (!self.data) {
      return;
    }
    data = [];
    name = [];
    ref = ['gender', 'first_name', 'last_name'];
    for (j = 0, len = ref.length; j < len; j++) {
      i = ref[j];
      field = self.data[`${i}`];
      if (i === "gender") {
        if (typeof field !== 'string') {
          field = `${field}`;
        }
        switch (field) {
          case "true":
            field = "Herr";
            break;
          case "false":
            field = "Frau";
            break;
          case "null":
            field = "keine Anrede";
        }
      }
      if (i === "last_name" && self.data.affix) {
        field += ",";
      }
      name.push(field);
    }
    name = name.join(" ");
    data.push(name);
    address = [];
    ref1 = ['address', 'zip', 'city'];
    for (k = 0, len1 = ref1.length; k < len1; k++) {
      i = ref1[k];
      if (!self.data[`${i}`]) {
        continue;
      }
      address.push(self.data[`${i}`]);
      if (i === 'address') {
        address.push("<br>");
      }
    }
    address = address.join(" ");
    if (address.length > 0) {
      data.push(address);
    }
    if (self.data.phone) {
      data.push(self.data.phone);
    }
    data.push(self.data.email);
    return data.join("<br>");
  };
}));
